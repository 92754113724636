





































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import {
  AppSection,
  Dictionary,
  DictionaryType,
  SUBSCRIPTION_EVENTS_DICTIONARIES,
} from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";
import {
  SubscriptionEventModel,
  SubscriptionEventType,
} from "@/settings/models/subscription-events/SubscriptionEventModel";

@Component
export default class SubscriptionEventForm extends Vue {
  @Prop() value!: SubscriptionEventModel;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: false }) isEdit!: boolean;
  @Ref("form") readonly form!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly types = Object.values(SubscriptionEventType).map((value) => ({
    text: this.$lang(`appAdmin.subscriptionEvents.type.${value.toLowerCase()}`),
    value,
  }));
  valid = false;
  search: string | null = null;
  eventNames: Array<Dictionary> = [];

  get applicationId(): string {
    return this.$route.params.id;
  }

  get tokenErrorMessages(): Array<string> {
    return this.isTokenUnique ? [] : [this.$lang("shared.errors.notUnique")];
  }

  get isSubscriptionEventSaving(): boolean {
    return this.$store.state.subscriptionEventsStore.isSubscriptionEventSaving;
  }

  get isTokenUnique(): boolean {
    return this.$store.state.subscriptionEventsStore.isTokenUnique;
  }

  get appVersions(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[DictionaryType.APP_VERSIONS]
      .values;
  }

  get appVersionsFrom(): Array<Record<string, any>> {
    return this.appVersions.map((version: Dictionary) => {
      if (
        !!this.value?.appVersionTo &&
        !this.value?.appVersionFrom &&
        version.value > this.value?.appVersionTo
      ) {
        return { ...version, disabled: true };
      } else if (
        !!this.value?.appVersionTo &&
        !!this.value?.appVersionFrom &&
        version.value > this.value?.appVersionTo
      ) {
        return { ...version, disabled: true };
      }

      return version;
    });
  }

  get appVersionsTo(): Array<Record<string, any>> {
    return this.appVersions.map((version: Dictionary) => {
      if (
        this.value &&
        !!this.value.appVersionFrom &&
        version.value < this.value.appVersionFrom
      ) {
        return { ...version, disabled: true };
      }

      return version;
    });
  }

  @Watch("applicationId", { immediate: true })
  async loadDictionaries() {
    await this.$store.dispatch("loadDictionaries", {
      app: this.applicationId,
      dictionaryTypes: SUBSCRIPTION_EVENTS_DICTIONARIES,
    });
    this.eventNames = this.$store.state.dictionaryStore[
      DictionaryType.EVENT_NAMES
    ].values.map(
      ({ value, name, parentValue }: Dictionary) =>
        new Dictionary(value, `${name} - ${value}`, parentValue)
    );
  }

  handleInputCombobox(newValue: Dictionary | string) {
    if (typeof newValue === "string") {
      this.value.token = newValue;
      this.eventNames.push(new Dictionary(newValue, newValue, null));
    } else {
      this.value.token = newValue.value;
    }

    this.$store.dispatch("checkIsSubscriptionEventTokenUnique", {
      applicationId: this.applicationId,
      token: this.value.token,
    });
  }

  cancel() {
    this.$router.push({
      name: AppSection.SUBSCRIPTION_EVENTS,
      params: { id: this.applicationId },
    });
  }

  save() {
    if (!this.form.validate()) {
      return;
    }

    this.$emit("save");
  }
}
