








import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import SubscriptionEventForm from "@/settings/components/subscription-events/SubscriptionEventForm.vue";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { AppSection } from "@/shared/models";
import { SubscriptionEventModel } from "@/settings/models/subscription-events/SubscriptionEventModel";

@Component({
  components: {
    SubscriptionEventForm,
  },
})
export default class NewSubscriptionEventView extends mixins(
  UnsavedChangesMixin
) {
  subscriptionEvent = new SubscriptionEventModel();

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.subscriptionEvent);
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.subscriptionEvents.newSubscriptionEventTitle")
    );
    this.handleWatchingUnsavedChanges();
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  async save() {
    await this.$store.dispatch("createSubscriptionEvent", {
      applicationId: this.applicationId,
      event: this.subscriptionEvent,
    });
    this.isSavedForm = true;
    this.$router.push({
      name: AppSection.SUBSCRIPTION_EVENTS,
    });
  }
}
